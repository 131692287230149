.sidebar--menu {
  &__header {
    width: 100%;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
  }

  &__container {
    background: #fff;
    width: 300px !important;

    &--header {
      box-shadow: 0px 0px 6px #000E9C33;
      text-align: center;
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .icon.times {
        font-size: 20px;
      }
    }

    >.ui.menu {
      border: 0;
      box-shadow: none;
      text-align: center;
      width: 100% !important;
      border-radius: 0px;
      margin: 0px;
      display: flex !important;
      flex-direction: column !important;
    }

    .dropdown--container {
      padding: 8px 16px;
    }
  }

  &__search--bar {
    width: 100%;

    & .input {
      width: 100%;
    }
  }

  .ui.vertical.menu {

    &.mobile-menu {

      &__secondary {
        border-top: 1px solid rgba(34, 36, 38, .1);
      }
    }
  }
}