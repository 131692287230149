.Speaker {
    &Modal {
        .ui.card.card--speaker {
            width: 100%;
            margin-top: 100px !important;
            border: none;
            box-shadow: none !important;

            &:hover {
                box-shadow: none !important;
            }

        }

        &--header {
            text-align: center;
        }

        &--visibleHeader {
            .close.icon {
                top: 0.8rem;
                right: 0.5rem;
            }
        }

    }
}

@media (max-width: 768px) {
    .Speaker {
        &Modal {
            .ui.card.card--speaker {
                flex-direction: column !important;
            }
        }
    }
}