.WorkshopAddToCalendarBlock {

  &--Container {
    margin-bottom: 5px;
  }

  &--Dropdown.ui.button.labeled {

    text-align: center;
    padding-left: 1.70em !important;
    padding-right: 0.8em !important;


    & > i {
      margin-left: -0.2em !important;
      background-color: transparent !important;
    }

    & > .text {
      font-size: 12px;
    }

    & > .menu.transition img {
      vertical-align: baseline !important;
      width: 1.5em;
      margin-right:0.35em;
      padding-bottom: 0.2em;
    }

    & > .menu.transition span {
      font-size: 0.95em !important;
    }
  }

  &--Modal {
    padding-bottom: 10px;
  }
}
